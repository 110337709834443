<template>
  <section v-if="loaded && gameCount">
    <transition name="fade">
      <div class="loading" v-if="!banner">
        <!--img src="@/assets/img/loading.gif" /-->
      </div>
    </transition>
    <transition name="fade">
      <ui-swiper/>
    </transition>
    <!-- <div id="main_iv_M">

    </div> -->
    <div class="scrNavi">
      <ul class="navi tabs">
        <!-- <li @click="currentTab = 'tab-1'" :class="{'on': currentTab == 'tab-1'}" data-tab="tab-1"><em class="all"></em><span>{{$t('front.gameCategory.all')}}</span></li> -->
        <li @click="currentTab = 'tab-2'" v-if="gameCount['casino']" :class="{'on': currentTab == 'tab-2'}" data-tab="tab-2"><a><em class="live"></em><span>{{$t('front.gameCategory.livecasino')}}</span></a></li>
        <!-- <li @click="currentTab = 'tab-3'" v-if="gameCount['hc-casino']" :class="{'on': currentTab == 'tab-3'}" data-tab="tab-3"><a><em class="hotel"></em><span>호텔카지노</span></a></li> -->
        <li @click="currentTab = 'tab-4'" v-if="gameCount['slot']" :class="{'on': currentTab == 'tab-4'}" data-tab="tab-4"><a><em class="slot"></em><span>{{$t('front.gameCategory.slot')}}</span></a></li>
        <li @click="currentTab = 'tab-7'" v-if="gameCount['h-slot']" :class="{'on': currentTab == 'tab-7'}" data-tab="tab-7"><a><em class="hotelslot"></em><span>{{$t('front.gnb.hotelslot')}}</span></a></li>
        <li @click="currentTab = 'tab-5'" v-if="gameCount['sport']" :class="{'on': currentTab == 'tab-5'}" data-tab="tab-5"><a><em class="sport"></em><span>{{$t('front.gnb.sport')}}</span></a></li>
        <li @click="currentTab = 'tab-6'" v-if="gameCount['minigame']" :class="{'on': currentTab == 'tab-6'}" data-tab="tab-6"><a><em class="mini"></em><span>{{$t('front.gnb.minigame')}}</span></a></li>
      </ul>
    </div>

    <transition-group name="fade" >
      <div class="tab-content" v-bind:key="currentTab">
        <ul class="gamewrap">
          <template v-for="game in gameList" v-bind:key="game.code">
            <li v-if="currentTab == 'tab-1' || (currentTab != 'tab-1' && currentTab==game.type)"
                :class="game.groupCode">
              <img :src="getBackgroundImage(game)" />
              <p class="name">
                {{$i18n.locale == 'ko' ? game.codeName : game.codeNameEN}}
                <em :class="game.groupCode">
                  <template v-if="game.groupCode === 'casino'">
                    <template v-if="game.groupCodeName.indexOf('HC-') === -1">
                      {{ $t('front.gnb.livecasino') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelcasino') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'slot'">
                    <template v-if="game.groupCodeName.indexOf('H-') === -1">
                      {{ $t('front.gnb.slot') }}
                    </template>
                    <template v-else>
                      {{ $t('front.gnb.hotelslot') }}
                    </template>
                  </template>
                  <template v-if="game.groupCode === 'minigame'">
                    {{ $t('front.gnb.minigame') }}
                  </template>
                  <template v-if="game.groupCode === 'sport'">
                    {{ $t('front.gnb.sport') }}
                  </template>
                </em>
              </p>
              <template v-if="game.groupCode == 'slot'">
                <a @click="slotOpen('isSlot', 'slot', game.codeName, game.code)"></a>
              </template>

              <template v-else-if="game.groupCode == 'new'">
                <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
              </template>

              <template v-else>
                <a @click="onCasinoSelectGame(game.groupCode, game.code)"></a>
              </template>
            </li>
          </template>
          <!--li v-if="currentTab == 'tab-3'" @click="notready">
            <img src="../assets/img/gamecard/dowinnslot.png"/>
            <p class="name">카지노 슬롯</p>
          </li-->
          <!--li v-if="currentTab == 'tab-3'" @click="notready">
            <img src="../assets/img/gamecard/main_power.jpg"/>
            <p class="name">파워볼</p>
          </li-->
          <!--li v-if="currentTab == 'tab-3'">
            <img src="../assets/img/gamecard/main_sport.png"/>
            <p class="name">스포츠</p>
            <a @click="onCasinoSelectGame('sport', 'splus')"></a>
          </li-->
        </ul>
      </div>
    </transition-group>
  </section>
  <!-- <section>
    <div class="servicecenter">
      <ul>
        <li>
            <h4>{{ $t('front.common.notice') }}</h4>
            <a @click="goPageByName('noticeList')">+ MORE</a>
        </li>
        <template v-for="item in noticeList" :key="item">
          <li @click="onClickNoticeRead(item)">
            <span>{{item.title}}</span>
            <span>{{item.regDate}}</span>
          </li>
        </template>
        <template v-for="item in listMaxCount - noticeList.length" :key="item">
          <li>
            <span>&nbsp;</span>
            <span>&nbsp;</span>
          </li>
        </template>
      </ul>
      <ul @click="goPageByName('')">
          <li>
            <h4>{{ $t('front.main.livecharge') }}</h4>
            <a>+ MORE</a>
        </li>
        <template v-for="item in cashInList" :key="item.memId">
          <li>
            <span>{{item.memId}}</span>
            <span><em>{{thousand(Number(item.cashAmt))}} 원</em>{{item.regDate}}</span>
          </li>
        </template>
        <template v-for="item in listMaxCount - cashInList.length" :key="item">
          <li>
            <span>&nbsp;</span>
            <span><em>&nbsp;</em>&nbsp;</span>
          </li>
        </template>
      </ul>
      <ul @click="goPageByName('')">
        <li>
            <h4>{{ $t('front.main.liveExchange') }}</h4>
            <a>+ MORE</a>
        </li>
        <template v-for="item in cashOutList" :key="item.memId">
          <li>
            <span>{{item.memId}}</span>
            <span><em>{{thousand(Number(item.cashAmt*-1))}} 원</em>{{item.regDate}}</span>
          </li>
        </template>
        <template v-for="item in listMaxCount - cashOutList.length" :key="item">
          <li>
            <span>&nbsp;</span>
            <span><em>&nbsp;</em>&nbsp;</span>
          </li>
        </template>
      </ul>
    </div>
  </section> -->
  <template v-for="(item) in popupList" v-bind:key="item.bannerIdx">
    <div class="mainPop" v-if="item.delYn == 'N' && !item.isClose" :data-idx="item.bannerIdx">
      <div class="closeWrap">
        <a class="close" @click="popupCloseByCookie(item)">{{$t('front.main.today')}}</a>
        <a class="close" @click="popupClose(item)">{{$t('front.main.close')}}</a>
      </div>
      <img :src="item.bannerImg" />
    </div>
  </template>

</template>

<script>

import { getPopupCookie, setPopupCookie, tokenCheck } from '@/libs/auth-helper'
import { getMain, getMainCashStatus, getMainNotice } from '@/api/main'
import { getSlotGame } from '@/api/game'
import { isMobile } from '@/libs/utils'
import { mapState } from 'vuex'
import UiSwiper from '@/components/ui/UiSwiperM.vue'
import store from '@/store'

export default {
  name: 'MIndex',
  components: {
    UiSwiper
  },
  computed: {
    ...mapState([
      'userData',
      'commonCode',
      'commonCodeByOrder',
      'commonCodeByCode',
      'popup',
      'popupLogin',
      'banner',
      'gameCount'
    ])
  },
  data () {
    return {
      loaded: false,
      currentTab: 'tab-2',
      gameList: [],
      cashInList: [],
      cashOutList: [],
      noticeList: [],
      listMaxCount: 5,
      noticeListMaxCount: 5,
      popupList: {}
    }
  },
  watch: {
    popup () {
      this.popupMerge()
    },
    popupLogin () {
      this.popupMerge()
    },
    async userData () {
      await store.dispatch('storePopup')
      if (this.userData) {
        await store.dispatch('storePopupLogin')
      }
      await this.popupMerge()
    }
  },
  methods: {
    notready () {
      alert('서비스 준비중입니다.')
    },
    onClickNoticeRead (item) {
      console.log(item)
      this.$router.push({ name: 'noticeRead', params: { boardIdx: item.boardIdx } })
    },
    loadCashStatus () {
      getMainCashStatus({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data)
          this.cashInList = result.data.cashInList
          this.cashOutList = result.data.cashOutList
        }
      })
    },
    loadNotice () {
      getMainNotice({}).then(res => {
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result)
          this.noticeList = result.data.boardList
        }
      })
    },
    loadMain () {
      if (this.userData) {
        getMain({}).then(res => {
          // console.log(res)
        })
      }
    },
    getBackgroundImage (item) {
      try {
        const image = require('../assets/img/game_main_pc_' + item.code + '_off.png')
        if (image) {
          return image
        } else {
          return ''
        }
      } catch (e) {
        return ''
      }
    },
    slotOpen (event, groupCode, codeName) {
      console.log(event, groupCode, codeName)
      this.emitter.emit(event, { groupCode, codeName })
    },
    popupMerge () {
      const popupLogin = this.userData ? this.popupLogin : []
      const pList = this.popup.concat(popupLogin)

      this.popupList = {}

      pList.forEach(item => {
        if (!item.isClose) {
          const key = item.bannerIdx
          const siteId = process.env.VUE_APP_SITE_ID || 'moka'
          const name = siteId + '_popup_' + item.bannerIdx
          const cookie = getPopupCookie(name)
          if (cookie) {
            item.isClose = true
          } else {
            item.isClose = false
          }
          if (!this.popupList[key]) {
            this.popupList[key] = item
          }
        }
      })
      console.log(this.popupList)
    },
    popupClose (item) {
      item.isClose = true
    },
    popupCloseByCookie (item) {
      item.isClose = true
      setPopupCookie(item)
    }
  },
  async created () {
    setTimeout(function () {
      const gameList = JSON.parse(JSON.stringify(this.commonCodeByCode))

      for (const item in gameList) {
        const game = gameList[item]
        let type = 'type2'
        if (game.groupCode === 'casino') {
          type = 'tab-2'
          if (game.groupCodeName.indexOf('HC-') > -1) {
            type = 'tab-3'
          }
        } else if (game.groupCode === 'slot') {
          type = 'tab-4'
          if (game.groupCodeName.indexOf('H-') > -1) {
            type = 'tab-7'
          }
        } else if (game.groupCode === 'minigame') {
          type = 'tab-6'
        } else if (game.groupCode === 'sport') {
          type = 'tab-5'
        }
        game.type = type
        this.gameList.push(game)
      }

      this.loaded = true

      if (this.$route.params.prodId && this.$route.params.type) {
        if (this.userData && this.userData.kplayId) {
          const params = {
            kplayId: this.userData.kplayId,
            gameCode: this.$route.params.prodId,
            gameGroupCode: 'slot',
            type: this.$route.params.type,
            prodId: this.$route.params.prodId
          }
          getSlotGame(params)
            .then(response => {
              const result = response.data
              if (result.resultCode === '0') {
                const data = result.data
                const gameUrl = data.gameurl

                const _width = 1100
                const _height = 680
                const _left = Math.ceil((window.screen.width - _width) / 2)
                const _top = 100
                if (isMobile()) {
                  // mobile
                  const p = window.open('/', '_blank')
                  p.location.href = gameUrl
                } else {
                  // pc
                  window.open(gameUrl, 'evolution', `width=${_width},height=${_height},left=${_left},top=${_top}`)
                }
              }
            })
        } else {
          this.onCheck('front.error.afterSignin')
        }
      }
    }.bind(this), 500)
  },
  async beforeRouteEnter (to, from, next) {
    await tokenCheck()
    next()
  }
}
</script>
<style scoped>
.loading {z-index: 10;position: relative;width: 100%;height: 78px;display:flex;align-items: center;justify-content: center;}
.loading img {height: 100%;}
.mainPop {position: relative;min-width: 320px;position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);background: #fff;border: 1px solid #1c594e;z-index: 100;}
.mainPop .closeWrap {width: calc(100% - 30px);display: flex;align-items: center;justify-content: end;gap: 15px;position: absolute;bottom: 0;padding: 15px;background: rgba(0,0,0,.7);color: #fff;font-size: 12px;}
.mainPop a.close {color: #fff;}
.mainPop>img {width: 320px;height: 440px;vertical-align: bottom;object-fit: cover;object-position: center;}
</style>
<style>
.mainSlide img{width:100%;height: 200px;object-fit: cover;object-position: center;}
.mainSlide .swiper-pagination-bullets{bottom:16px;text-align:center;}
.mainSlide .swiper-pagination-bullets span:hover,.mainSlide2 .swiper-pagination-bullets span.swiper-pagination-bullet-active{opacity:1;}
.mainSlide .swiper-pagination-bullets span{width:35px;height:4px;margin:0;background:#fff;opacity:.3;border-radius:0;margin:0 !important;}
.mainSlide .swiper-slide>a{width:100%;display:block;}

.swiper-button-next {
  background-image: url('~@/assets/img/slideright.png') !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  bottom: 50%;
  z-index: 11;
}
.swiper-button-prev {
  background-image: url('~@/assets/img/slideleft.png') !important;
  background-size: 11px 20px !important;
  background-repeat: no-repeat;
  background-position: center;
  left: 10px;
  bottom: 50%;
  z-index: 11;
}
.swiper-pagination-bullet {display: none;}
</style>
<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/common.css"></style>
<style scoped src="@/styles_mobile/main.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
